<template>
  <MDBListGroup v-if="items.length">
    <MDBListGroupItem v-for="item in items" :key="item.id" class="d-flex align-items-center">
      <p class="flex-grow-1 mb-0">{{ item.name }}</p>

      <MDBBtnGroup>
        <MDBBtn color="primary" @click="emit('edit', item.id)">
          <Icon name="mdi:pencil" />
          Edit
        </MDBBtn>
        <MDBPopconfirm class="btn-danger" placement="top" @confirm="emit('delete', item.id)">
          <Icon name="mdi:delete" />
          Delete
        </MDBPopconfirm>
      </MDBBtnGroup>
    </MDBListGroupItem>
  </MDBListGroup>
  <ClientOnly v-else>
    <MDBAlert color="primary" static>
      <Icon name="mdi:info-circle" />
      No options found.
    </MDBAlert>
  </ClientOnly>
</template>

<script setup lang="ts">
import { collection } from "firebase/firestore";
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBBtn,
  MDBAlert,
  MDBBtnGroup,
  MDBPopconfirm,
} from "mdb-vue-ui-kit";

const { vendorRef } = useVendor();
const emit = defineEmits<{
  (e: "edit", value: OptionGroup["id"]): void;
  (e: "delete", value: OptionGroup["id"]): void;
}>();

if (!vendorRef)
  throw createError({
    statusCode: 500,
    message: "Could not find vendor",
  });

const itemsCollection = collection(vendorRef, "options");
const items = useFirestore(itemsCollection, []);
</script>
