<template>
  <MDBListGroup v-if="items.length">
    <MDBListGroupItem v-for="item in items" :key="item.id" class="d-flex align-items-center">
      <p class="flex-grow-1 mb-0">
        {{ item.name }}
        <span class="text-muted text-small">
          {{ getMenuCategories(item) }}
        </span>
      </p>

      <!-- FIXME: <VendorToggleItemAvailability :id="item.id" /> -->
      <MDBBtnGroup>
        <MDBBtn color="primary" @click="emit('edit', item.id)">
          <Icon name="mdi:pencil" />
          Edit
        </MDBBtn>
        <MDBPopconfirm class="btn-danger" placement="top" @confirm="emit('delete', item.id)">
          <Icon name="mdi:delete" />
          Delete
        </MDBPopconfirm>
      </MDBBtnGroup>
    </MDBListGroupItem>
  </MDBListGroup>
  <ClientOnly v-else>
    <MDBAlert color="primary" static>
      <Icon name="mdi:info-circle" />
      No items found.
    </MDBAlert>
  </ClientOnly>
</template>

<script setup lang="ts">
import { collection, query, where, documentId, type CollectionReference } from "firebase/firestore";
import {
  MDBAlert,
  MDBBtnGroup,
  MDBListGroup,
  MDBListGroupItem,
  MDBBtn,
  MDBPopconfirm,
} from "mdb-vue-ui-kit";

const { vendorRef } = useVendor();
const emit = defineEmits<{
  (e: "edit", value: MenuItem["id"]): void;
  (e: "delete", value: MenuItem["id"]): void;
}>();

if (!vendorRef)
  throw createError({
    statusCode: 500,
    message: "Could not find vendor",
  });

const itemsCollection = collection(vendorRef, "items") as CollectionReference<MenuItem>;
const items = useFirestore(itemsCollection, []);

const props = defineProps<{
  menuCategoryIds: MenuCategory["id"][];
}>();
const catQuery = computed(() => {
  if (!props.menuCategoryIds.length) return null;
  const catCollection = collection(vendorRef, "category") as CollectionReference<MenuCategory>;
  return query(catCollection, where(documentId(), "in", props.menuCategoryIds));
});
const categories = useFirestore(catQuery, []);

function getMenuCategories(item: MenuItem) {
  const cats = categories.value
    .filter((c) => c.items.map((i) => i.id).includes(item.id))
    .map((c) => c.name);
  if (cats.length) return " - " + cats.join(", ");
  return "";
}
</script>
