<template>
  <FormEditText
    v-model="menuName"
    placeholder="Our menu"
    label="Menu name"
    @save="saveMenuName"
    noDelete
  />
</template>

<script lang="ts" setup>
import { set } from "@vueuse/core";
import { doc, type DocumentReference, updateDoc } from "firebase/firestore";

const props = defineProps<{
  menu: Menu;
}>();

const { vendorRef } = storeToRefs(useVendor());
const { setToast } = useToast();

const menuName = ref<string>("");
const menuRef = computed(() => {
  const v = unref(vendorRef);
  return v && props.menu.id ? (doc(v, "menus", props.menu.id) as DocumentReference<Menu>) : null;
});

watchOnce(
  () => props.menu.name,
  (name) => {
    set(menuName, name);
  },
  {
    immediate: true,
  }
);

const saveMenuName = async () => {
  console.log(menuName.value);

  if (!menuRef.value)
    return setToast({
      title: "Error",
      message: "Could not get menu reference",
      type: "danger",
    });

  await updateDoc(menuRef.value, { name: menuName.value });

  setToast({
    title: "Success",
    message: "Saved menu name",
    type: "success",
  });
};
</script>
