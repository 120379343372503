<template>
  <MDBInput
    inputGroup
    :formOutline="false"
    wrapperClass="mb-3"
    v-model="modelValue"
    :placeholder="props.placeholder ?? props.label"
    :aria-label="props.label"
    :invalid-feedback="props.invalidFeedback"
    :is-valid="props.isValid"
    :is-validated="props.isValidated"
  >
    <template #prepend>
      <span class="input-group-text">
        {{ props.label }}
      </span>
    </template>
    <MDBBtnGroup>
      <MDBBtn
        v-if="!props.noSave"
        outline="success"
        :ripple="{ color: 'dark' }"
        @click="emit('save')"
      >
        <Icon name="mdi:check-bold" />
        <span class="visually-hidden">Save</span>
      </MDBBtn>
      <MDBBtn
        v-if="!props.noDelete"
        outline="danger"
        :ripple="{ color: 'dark' }"
        @click="emit('delete')"
      >
        <Icon name="mdi:cancel-bold" />
        <span class="visually-hidden">Delete</span>
      </MDBBtn>
    </MDBBtnGroup>
  </MDBInput>
</template>

<script lang="ts" setup>
import { MDBInput, MDBBtn, MDBBtnGroup } from "mdb-vue-ui-kit";

const props = defineProps<{
  label: string;
  placeholder?: string;
  invalidFeedback?: string;
  isValid?: boolean;
  isValidated?: boolean;
  noSave?: boolean;
  noDelete?: boolean;
}>();
const modelValue = defineModel<string | number>({
  required: true,
});
const emit = defineEmits<{
  (e: "save"): void;
  (e: "delete"): void;
}>();
</script>
