<template>
  <MDBListGroup v-if="categories.length">
    <MDBListGroupItem
      v-for="category in categories"
      :key="category.id"
      class="d-flex align-items-center"
    >
      <p class="flex-grow-1 mb-0">{{ category.name }}</p>
      <MDBBtnGroup>
        <MDBBtn color="primary" @click="emit('edit', category.id)">
          <Icon name="mdi:pencil" />
          Edit
        </MDBBtn>
        <MDBPopconfirm class="btn-danger" placement="top" @confirm="emit('delete', category.id)">
          <Icon name="mdi:delete" />
          Delete
        </MDBPopconfirm>
      </MDBBtnGroup>
    </MDBListGroupItem>
  </MDBListGroup>
  <ClientOnly v-else>
    <MDBAlert color="primary" static>
      <Icon name="mdi:info-circle" />
      No categories found.
    </MDBAlert>
  </ClientOnly>
</template>

<script setup lang="ts">
import { collection, query, where, documentId } from "firebase/firestore";
import {
  MDBAlert,
  MDBBtnGroup,
  MDBListGroup,
  MDBListGroupItem,
  MDBBtn,
  MDBPopconfirm,
} from "mdb-vue-ui-kit";

const { vendorRef } = useVendor();
const props = defineProps<{
  menuCategoryIds: MenuCategory["id"][];
}>();

const emit = defineEmits<{
  (e: "edit", value: MenuCategory["id"]): void;
  (e: "delete", value: MenuCategory["id"]): void;
}>();

if (!vendorRef)
  throw createError({
    statusCode: 500,
    message: "Could not find vendor",
  });

const catQuery = computed(() => {
  if (!props.menuCategoryIds.length) return null;
  const catCollection = collection(vendorRef, "category");
  return query(catCollection, where(documentId(), "in", props.menuCategoryIds));
});

const categories = useFirestore(catQuery, []);
</script>
