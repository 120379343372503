<!-- set up and manage menu items -->
<!-- menu categories, menu items -->
<!-- category name, category description, icon, active/disactive, delete -->
<!-- subcategory name, subcategory description, icon, active/disactive, delete -->
<!-- menu item name, menu item description, menu items cost, menu items add to cart button, icon, quantity if applicable (notification based on quantity), active/disactive, delete -->

<!-- PRIORITY -->

<template>
  <MDBRow class="gap-1 my-3">
    <MDBCol col="12">
      <div class="d-flex justify-content-between align-items-center">
        <h1>Menu Manager</h1>
        <MDBPopconfirm
          v-if="menu && menu.id !== 'default'"
          message="Are you sure you want to delete this menu?"
          yes-text="Yes"
          no-text="No"
          @confirm="deleteMenu"
        >
          Delete Menu
        </MDBPopconfirm>
      </div>
      <FormMenuName v-if="menu" :menu="menu" />
    </MDBCol>
    <MDBCol col="12">
      <MDBAccordion stayOpen>
        <MDBAccordionItem headerTitle="Menu Categories" collapseId="stayOpen-collapseOne">
          <p>Categories - Example: Breakfast, Lunch, Specials</p>

          <VendorMenuManagerCategory
            v-if="menu"
            class="mb-2"
            :menuCategoryIds="menuCategoryIds"
            @delete="deleteCategory($event)"
            @edit="editCategory($event)"
          />

          <MDBBtn color="primary" class="" @click="addNewCategory">
            <Icon name="mdi:plus" />
            Add Category
          </MDBBtn>

          <LazyVendorModalEditCategory v-model="editCategoryModal" :id="selectedCategoryID" />
        </MDBAccordionItem>
        <MDBAccordionItem headerTitle="Menu Item" collapseId="stayOpen-collapseTwo">
          <p>Items - Example: Burgers, Wraps, etc.</p>
          <VendorMenuManagerItems
            v-if="menu"
            class="mb-2"
            :menuCategoryIds="menuCategoryIds"
            @edit="editItem($event)"
            @delete="deleteItem($event)"
          />

          <MDBBtn color="primary" class="" @click="addNewItem">
            <Icon name="mdi:plus" />
            Add Item
          </MDBBtn>

          <LazyVendorModalEditItem v-model="editItemModal" :id="selectedItemID" />
        </MDBAccordionItem>
        <MDBAccordionItem headerTitle="Menu Item Options" collapseId="stayOpen-collapseThree">
          <p>Options - Example: Size, Add-ons, etc.</p>

          <VendorMenuManagerOptions
            v-if="menu"
            class="mb-2"
            @edit="editOption"
            @delete="deleteOption"
          />

          <MDBBtn color="primary" class="" @click="addNewOptionGroup">
            <Icon name="mdi:plus" />
            Add Option Group
          </MDBBtn>

          <LazyVendorModalEditOptionGroup v-model="editOptionModal" :id="selectedOptionID" />
        </MDBAccordionItem>
      </MDBAccordion>
    </MDBCol>
  </MDBRow>
</template>

<script setup lang="ts">
import { set } from "@vueuse/core";
import type { FirebaseError } from "firebase/app";
import {
  updateDoc,
  getDocs,
  doc,
  collection,
  arrayUnion,
  arrayRemove,
  addDoc,
  deleteDoc,
  query,
  writeBatch,
  where,
  getDoc,
} from "firebase/firestore";
import {
  MDBCol,
  MDBRow,
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBPopconfirm,
} from "mdb-vue-ui-kit";
const { setToast } = useToast();

definePageMeta({
  title: "Menu Manager",
  middleware: ["auth"],
  requiredRoles: new Set("vendor"),
  async validate({ params }) {
    if (!("menuid" in params)) return false;
    // Check if the menu exists
    const { uid } = (await getCurrentUser())!;
    const docRef = menuRef(uid, params.menuid as string);
    const docSnap = await getDoc(docRef!);
    return docSnap.exists();
  },
});

const {
  params: { menuid },
} = useRoute("vendor-menu-manager-menuid");
const { uid } = useUser();
const menu = useFirestore(menuRef(uid, menuid as string));
const menuCategoryIds = computed(() => {
  const categories = menu.value?.categories ?? [];
  return categories.map((cat) => cat.id);
});

const selectedCategoryID = ref("");
const selectedItemID = ref("");
const selectedOptionID = ref("");

const addNewItem = async () => {
  const itemsCollection = collection(vendorRef(uid)!, "items");
  const newItem: OmitId<MenuItem> = {
    name: "Menu Item",
    description: "",
    price: 0,
    options: [],
    available: true,
  };
  try {
    await addDoc(itemsCollection, newItem);
  } catch (e) {
    const error = e as FirebaseError;
    console.error(e);
    setToast({
      type: "danger",
      title: error.name ?? "Error",
      message: error.message ?? "An error occurred while adding a new item",
    });
  }
};
const addNewCategory = async () => {
  const vendorCategories = collection(vendorRef(uid)!, "category");
  const data: OmitId<MenuCategory> = {
    name: "New Category",
    description: "",
    items: [],
    status: false,
  };

  try {
    const newCategory = await addDoc(vendorCategories, data);
    await updateDoc(menuRef(uid, menuid as string)!, "categories", arrayUnion(newCategory));
  } catch (e) {
    const error = e as FirebaseError;
    console.error(e);
    setToast({
      type: "danger",
      title: error.name ?? "Error",
      message: error.message ?? "An error occurred while adding a new category",
    });
  }
};
const addNewOptionGroup = async () => {
  if (!vendorRef)
    return setToast({
      type: "danger",
      title: "Error",
      message: "Could not create new option group",
    });

  const optionsCollection = collection(vendorRef(uid)!, "options");
  const newOptionGroup: OmitId<OptionGroup> = {
    name: "Option Group",
    options: [],
  };
  try {
    await addDoc(optionsCollection, newOptionGroup);
  } catch (e) {
    const error = e as FirebaseError;
    console.error(e);
    setToast({
      type: "danger",
      title: error.name ?? "Error",
      message: error.message ?? "An error occurred while adding a new option group",
    });
  }
};
const deleteCategory = async (catId: string) => {
  const docRef = doc(vendorRef(uid)!, "category", catId);

  try {
    await updateDoc(menuRef(uid, menuid as string)!, "categories", arrayRemove(docRef));
    await deleteDoc(docRef);
  } catch (e) {
    const error = e as FirebaseError;
    console.error(e);
    setToast({
      type: "danger",
      title: error.name ?? "Error",
      message: error.message ?? "An error occurred while deleting a category",
    });
  }
};
const deleteItem = async (itemId: string) => {
  const docRef = doc(vendorRef(uid)!, "items", itemId);
  const catCollection = collection(vendorRef(uid)!, "category");
  const itemsQuery = query(catCollection, where("items", "array-contains", docRef));
  const catsToUpdate = await getDocs(itemsQuery);

  try {
    await Promise.all(
      catsToUpdate.docs.map(async (cat) => {
        const catRef = doc(catCollection, cat.id);
        await updateDoc(catRef, "items", arrayRemove(docRef));
      })
    );
    await deleteDoc(docRef);
  } catch (e) {
    const error = e as FirebaseError;
    console.error(e);
    setToast({
      type: "danger",
      title: error.name ?? "Error",
      message: error.message ?? "An error occurred while deleting an item",
    });
  }
};
const deleteOption = async (id: string) => {
  const docRef = doc(vendorRef(uid)!, "options", id);
  const itemCollection = collection(vendorRef(uid)!, "items");
  const itemsQuery = query(itemCollection, where("options", "array-contains", docRef));
  const itemsToUpdate = await getDocs(itemsQuery);

  try {
    await Promise.all(
      itemsToUpdate.docs.map(async ({ id }) => {
        const smolDocRef = doc(itemCollection, id);
        await updateDoc(smolDocRef, "options", arrayRemove(docRef));
      })
    );
    await deleteDoc(docRef);
  } catch (e) {
    const error = e as FirebaseError;
    console.error(e);
    setToast({
      type: "danger",
      title: error.name ?? "Error",
      message: error.message ?? "An error occurred while deleting an option",
    });
  }
};

const editCategoryModal = ref(false);
const editCategory = (id: string) => {
  set(selectedCategoryID, id);
  set(editCategoryModal, true);
};
const editItemModal = ref(false);
const editItem = (id: string) => {
  set(selectedItemID, id);
  set(editItemModal, true);
};
const editOptionModal = ref(false);
const editOption = (id: string) => {
  set(selectedOptionID, id);
  set(editOptionModal, true);
};

const deleteMenu = async () => {
  const { $firebaseFirestore } = useNuxtApp();
  const batch = writeBatch($firebaseFirestore);
  batch.delete(menuRef(uid, menuid as string)!);
  batch.update(vendorRef(uid)!, "menus", arrayRemove(menu));

  await batch
    .commit()
    .then(() => {
      navigateTo("/vendor");
      setToast({
        type: "success",
        title: "Success",
        message: "Menu deleted",
      });
    })
    .catch((e) => {
      const error = e as FirebaseError;
      console.error(e);
      setToast({
        type: "danger",
        title: error.name ?? "Error",
        message: error.message ?? "An error occurred while deleting the menu",
      });
    });
};
</script>
